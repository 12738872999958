import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { InputField } from './input-field';
import { useAuth } from '../hooks/use-auth';
import { usePopover } from '../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { Logout as LogoutIcon } from '../icons/logout';
import { User as UserIcon } from '../icons/user';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRectangleList } from "@fortawesome/free-solid-svg-icons";
import { faUser, faListUl, faArrowRightFromBracket, faLifeRing, faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from '../store';
import { openBugReportDialog, closeBugReportDialog } from '../slices/bugReportSlice';
import { BugReportDialog } from '../components/quick-actions/bug-report-dialog';

interface Organization {
  id: string;
  name: string;
}

interface AccountPopoverProps {
  currentOrganization: Organization;
  darkMode: boolean;
  onLanguageChange: (newLanguage: 'en' | 'fr') => void;
  onOrganizationChange: (organizationId: string) => void;
  onSwitchDirection: () => void;
  onSwitchTheme: () => void;
  organizations: Organization[];
  rtlDirection: boolean;
}

const languageOptions = {
  en: {
    label: 'English'
  },
  fr: {
    label: 'French'
  }
};

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const {
    currentOrganization,
    darkMode,
    onLanguageChange,
    onOrganizationChange,
    onSwitchDirection,
    onSwitchTheme,
    organizations,
    rtlDirection,
    ...other
  } = props;
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { bugReportDialogOpen } = useSelector((state: RootState) => state.bugReport);

  const handleOrganizationChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onOrganizationChange?.(event.target.value);
  };

  const handleLanguageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onLanguageChange(event.target.value as 'en' | 'fr');
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          ml: 2
        }}
        {...other}
      >
        <Avatar
          src="/static/user-avatar.png"
          variant="rounded"
          sx={{
            height: 30,
            width: 30,
            borderRadius: 100
          }}
        />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            },
            flex: 1,
            // ml: 1,
            // minWidth: 120
          }}
        >
          {/* <div>
            <Typography
              sx={{
                color: lightNeutral[500]
              }}
              variant="caption"
            >
              Operation
            </Typography>
            <Typography
              sx={{ color: 'primary.contrastText' }}
              variant="subtitle2"
            >
              Chen Simmons
            </Typography>
          </div> */}
          <ChevronDownIcon
            sx={{
              color: 'primary.main',
              ml: 1
            }}
          />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 260,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'background.default'
          }
        }}
      >
        <InputField
          fullWidth
          onChange={handleOrganizationChange}
          select
          SelectProps={{ native: true }}
          value={currentOrganization.id}
          sx={{
            display: {
              md: 'none'
            },
            pt: 2,
            px: 2
          }}
        >
          {organizations.map((organization) => (
            <option
              key={organization.id}
              value={organization.id}
            >
              {organization.name}
            </option>
          ))}
        </InputField>
        <List>
          {/* <ListItem divider>
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                src="/static/user-avatar.png"
              />
            </ListItemAvatar>
            <ListItemText
              primary="Chen Simmons"
              secondary="ACME Corp LLC."
            />
          </ListItem> */}
          {/* <li>
            <List disablePadding>
              <ListSubheader disableSticky>
                {t('navbar_app_settings')}
              </ListSubheader>
              <ListItem
                sx={{
                  display: {
                    md: 'none',
                    xs: 'flex'
                  }
                }}
              >
                <InputField
                  fullWidth
                  onChange={handleLanguageChange}
                  select
                  SelectProps={{ native: true }}
                  value={i18n.language}
                >
                  {Object.keys(languageOptions).map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {languageOptions[option].label}
                    </option>
                  ))}
                </InputField>
              </ListItem>
              <ListItem
                sx={{
                  py: 0,
                  display: {
                    md: 'none',
                    xs: 'flex'
                  }
                }}
              >
                <Switch
                  checked={darkMode}
                  onChange={onSwitchTheme}
                />
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Dark Mode
                </Typography>
              </ListItem>
              <ListItem
                divider
                sx={{ pt: 0 }}
              >
                <Switch
                  checked={rtlDirection}
                  onChange={onSwitchDirection}
                />
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  RTL
                </Typography>
              </ListItem>
            </List>
          </li> */}
          {/* <ListItem
            button
            component={RouterLink}
            divider
            onClick={handleClose}
            to="/dashboard/organization"
          >
            <ListItemIcon>
              <OfficeBuildingIcon />
            </ListItemIcon>
            <ListItemText primary={t('organization')} />
          </ListItem> */}
          <ListItem
            dense
            button
            component={RouterLink}
            divider
            onClick={handleClose}
            to="/account"
          >
            <ListItemIcon>
              {/* <UserIcon /> */}
              <FontAwesomeIcon icon={faUser as IconProp} size="xs" />
            </ListItemIcon>
            <ListItemText primary={t('account')} />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            divider
            onClick={handleClose}
            to="/projects"
          >
            <ListItemIcon>
              {/* <UserIcon /> */}
              {/* <FontAwesomeIcon icon={faRectangleList as IconProp}/> */}
              <FontAwesomeIcon icon={faListUl as IconProp} size="xs" />
            </ListItemIcon>
            <ListItemText primary={t('projects')} />
          </ListItem>
          <ListItem
            button
            divider
            onClick={handleLogout}
          >
            <ListItemIcon>
              {/* <LogoutIcon /> */}
              <FontAwesomeIcon icon={faArrowRightFromBracket as IconProp} size="xs" />
            </ListItemIcon>
            <ListItemText primary={t('logout')} />
          </ListItem>
          <ListItem
            button
            divider
            onClick={() => dispatch(openBugReportDialog({element: {}, isEditabled: true, isDuplicated: false}))}
          >
            <ListItemIcon>
              {/* <LogoutIcon /> */}
              <FontAwesomeIcon icon={faCircleExclamation as IconProp} size="xs" />
            </ListItemIcon>
            <ListItemText primary={t('report_a_bug')} />
          </ListItem>
          <ListItem
            // button
            // onClick={handleLogout}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faLifeRing as IconProp} size="xs" />
            </ListItemIcon>
            <ListItemText primary="Support" secondary="admin@ipoptools.com" />
          </ListItem>
        </List>
      </Popover>
      <BugReportDialog open={bugReportDialogOpen} onClose={() => dispatch(closeBugReportDialog())} />
    </>
  );
};

AccountPopover.propTypes = {
  // @ts-ignore
  currentOrganization: PropTypes.object.isRequired,
  darkMode: PropTypes.bool.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  onOrganizationChange: PropTypes.func.isRequired,
  onSwitchDirection: PropTypes.func.isRequired,
  onSwitchTheme: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  rtlDirection: PropTypes.bool.isRequired
};
