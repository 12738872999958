import { useEffect, useState } from 'react';
import type { FC, ElementType } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, IconButton, List } from '@material-ui/core';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { Scrollbar } from './scrollbar';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
import { Cog as CogIcon } from '../icons/cog';
import { ViewList as ViewListIcon } from '../icons/view-list';
import { Download as DownloadIcon } from '../icons/download';
import { useSelector, RootState } from '../store';
import { useAuth } from '../hooks/use-auth';
import { useSettings } from '../contexts/settings-context';
import { openBugReportDialog, closeBugReportDialog } from '../slices/bugReportSlice';
import { BugReportDialog } from '../components/quick-actions/bug-report-dialog';
import { useDispatch } from "react-redux";
import { IconProp, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dashboard as DashboardIcon } from '../icons/dashboard';
import { faGear, faQuestion, faBallotCheck, faGaugeMax, faMemo, faU } from "@fortawesome/pro-duotone-svg-icons";

interface DashboardSidebarProps {
  onPin: () => void;
  pinned: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon?: IconProp;
  items?: Array<{ href: string; title: string; confidenceIndice: number; }>
  title: string;
  confidenceIndice?: number;
}

// const items: Item[] = [
//   {
//     icon: ChartPieIcon,
//     title: 'Reports',
//     items: [
//       {
//         href: '/reports',
//         title: 'Overview'
//       },
//       {
//         href: '/reports/sales',
//         title: 'Sales'
//       }
//     ]
//   },
//   {
//     icon: CogIcon,
//     title: 'Account',
//     items: [
//       {
//         href: '/account',
//         title: 'General Settings'
//       },
//       {
//         href: '/account/notifications',
//         title: 'Notifications'
//       }
//     ]
//   },
//   {
//     icon: OfficeBuildingIcon,
//     title: 'Organization',
//     items: [
//       {
//         href: '/organization',
//         title: 'General Settings'
//       },
//       {
//         href: '/organization/team',
//         title: 'Team'
//       },
//       {
//         href: '/organization/billing',
//         title: 'Billing'
//       }
//     ]
//   }
// ];

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);
  const { accessRights, activeProject } = useAuth() as any;
  const { settings, saveSettings } = useSettings();

  // Dynamic elements
  // Review 1
  const { customers, companies, companyFit } = useSelector((state: RootState) => state.review1);
  const [review1ConfidenceIndice, setReview1ConfidenceIndice] = useState(1);

  useEffect(() => {
    let review1CI = customers.data.map(c => c['confidence_indice'])
    // review1CI.push(companyFit.confidence_indice)
    review1CI = review1CI.concat(companies.data.map(c => c['confidence_indice']));
    console.log("review1CI", review1CI);
    if (review1CI.length > 0) {
      let newConfidenceIndice = Math.min(...review1CI);
      setReview1ConfidenceIndice(newConfidenceIndice);
    };
  }, [customers, companies])

  // Review 2
  const { review2 } = useSelector((state: RootState) => state.review2);

  // Review 3
  const { review3 } = useSelector((state: RootState) => state.review3);

  // Review 4
  const { review4 } = useSelector((state: RootState) => state.review4);

  const dispatch = useDispatch();
  const { bugReportDialogOpen } = useSelector((state: RootState) => state.bugReport);

  const localItems: Item[] = []

  if (accessRights) {
    localItems.push({
      icon: faGaugeMax as IconProp,
      title: 'Dashboard',
      href: '/'
    })

    if (accessRights['review_1'] === 1 || accessRights['review_1'] === 2) {
      let customerItems = [
        {
          href: '/review-1/customers',
          title: 'customers',
          confidenceIndice: null
        },
      ]

      customers.data.forEach(c => {
        customerItems.push(
          {
            href: `/review-1/customer-fit/${c['id']}`,
            title: `${c['name']}`,
            confidenceIndice: c['confidence_indice']
          }
        )
      });

      customerItems.push(
        {
          href: '/review-1/companies',
          title: 'company_fit',
          confidenceIndice: null
        }
      );

      companies.data.forEach(c => {
        customerItems.push(
          {
            href: `/review-1/company-fit/${c['id']}`,
            title: `${c['name']}`,
            confidenceIndice: c['confidence_indice']
          }
        )
      });

      localItems.push(
        {
          title: 'review_1',
          confidenceIndice: review1ConfidenceIndice ? review1ConfidenceIndice : 1,
          items: customerItems
        }
      )
    }

    if (accessRights['review_2'] === 1 || accessRights['review_2'] === 2) {
      localItems.push(
        {
          // icon: OfficeBuildingIcon,
          title: 'review_2',
          href: '/review-2/definition-of-success',
          confidenceIndice: review2?.confidence_indice,
        }
      )
    }

    if (accessRights['review_3'] === 1 || accessRights['review_3'] === 2) {
      localItems.push(
        {
          // icon: OfficeBuildingIcon,
          title: 'review_3',
          href: '/review-3/factors',
          confidenceIndice: review3?.confidence_indice,
        }
      )
    }

    if (accessRights['review_4'] === 1 || accessRights['review_4'] === 2) {
      localItems.push(
        {
          // icon: OfficeBuildingIcon,
          title: 'review_4',
          href: '/review-4/moves',
          confidenceIndice: review4?.confidence_indice,
        }
      )
    }

    if (accessRights['review_4'] === 1 || accessRights['review_4'] === 2) {
      localItems.push(
        {
          icon: faU as IconProp,
          title: 'unknowns',
          href: '/unknowns'
        }
      )
    }

    if (accessRights['review_4'] === 1 || accessRights['review_4'] === 2) {
      localItems.push(
        {
          icon: faBallotCheck as IconProp,
          title: 'validations',
          href: '/validations'
        }
      )
    }

    if (accessRights['review_4'] === 1 || accessRights['review_4'] === 2) {
      localItems.push(
        {
          icon: faMemo as IconProp,
          title: 'reports',
          href: '/reports',
        }
      )
    }

    if (accessRights['project_admin_space'] === 1) {
      localItems.push(
        {
          icon: faGear as IconProp,
          title: 'project_settings',
          href: '/project'
        }
      )
    }

  }

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    localItems.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      open
      sx={{ zIndex: 1000, border: 'none' }}
      variant="permanent"
      elevation={7}
      PaperProps={{
        onMouseOver: () => { setHovered(true); },
        onMouseLeave: () => { setHovered(false); },
        sx: {
          backgroundColor: 'background.default',
          border: 'none',
          boxShadow: '0px 8px 12px rgba(9, 30, 66, 0.08)',
          // height: 'calc(100% - 64px)',
          height: 'calc(100%)',
          overflowX: 'hidden',
          // top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 250 : 73,
          '& .simplebar-content': {
            height: '100%'
          },
          '&:hover': {
            width: 250,
            '& span, p': {
              display: 'flex'
            }
          }
        }
      }}
    >
      <Scrollbar
        // style={{
        //   // display: 'flex',
        //   // flex: 1,
        //   overflowX: 'hidden',
        //   overflowY: 'scroll'
        // }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          {/* <List disablePadding>
            {activeItem && (
              localItems.map((item) => (
                <DashboardSidebarItem
                  active={activeItem?.title === item.title}
                  activeHref={activeHref}
                  key={item.title}
                  onOpen={() => handleOpenItem(item)}
                  open={openedItem?.title === item.title && (hovered || pinned)}
                  pinned={pinned}
                  {...item}
                />
              ))
            )}
          </List> */}
          <img src={`/static/ipop-logo-${settings.theme}.png`} width={'100%'} />
          <Divider style={{marginTop: 20, marginBottom: 20}} />
          <List disablePadding>
            {localItems.map((item) => (
              <DashboardSidebarItem
                active={activeItem?.title === item.title}
                activeHref={activeHref}
                key={item.title}
                onOpen={() => handleOpenItem(item)}
                open={openedItem?.title === item.title && (hovered || pinned)}
                pinned={pinned}
                {...item}
              />
            ))
            }
          </List>
          {/* <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <IconButton onClick={() => dispatch(openBugReportDialog({element: {}, isEditabled: true, isDuplicated: false}))}>
              <FontAwesomeIcon icon={faBug as IconProp} size={"xs"} />
            </IconButton>
          </Box> */}
        </Box>
      </Scrollbar>
      <BugReportDialog open={bugReportDialogOpen} onClose={() => dispatch(closeBugReportDialog())} />
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool
};
